import _ from "lodash";
import React from "react";
import { Icon, Link, Dropdown } from "..";

class Avatar extends React.Component {
    constructor(props) {
        super(props);

        this.state = { showMenu: false };
        this._setRef = this._setRef.bind(this);
        this._handleDropdown = this._handleDropdown.bind(this);
    }

    _setRef = node => {
        this.dropdownRef = node;
    };

    _handleDropdown = e => {
        const { showMenu } = this.state;
        if (this.dropdownRef && !this.dropdownRef.contains(e.target)) {
            if (showMenu === true) this.setState({ showMenu: !showMenu });
        }
    };

    componentDidMount() {
        document.addEventListener("mousedown", this._handleDropdown);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this._handleDropdown);
    }

    render() {
        let {
            to,
            as = "div",
            menu = [],
            size = 40,
            className,

            shape = "circular",
            rounded = false,
            square = false,

            source = "fas user",
            sourceURL = "",
            image = false,
            pattern = false,
            dicebear = false,
            centralized = false
        } = this.props;

        const { fn } = window;
        const { showMenu } = this.state;
        let usingLink = typeof to !== "undefined";
        let usingOnClick = typeof this.props.onClick !== "undefined";

        // Change shape
        // =====================================================
        if (rounded === true) shape = "rounded";
        else if (square === true) shape = "square";

        // Avatar variables
        // =====================================================
        let Node = usingLink ? Link : as;
        let type = "icon";
        let avatarClass = `om ${shape} avatar`;
        let actualProps = _.omit(this.props, omitProps);

        // When menu is present, we say that the avatar is clickable
        if (_.isArray(menu) && menu.length > 0) {
            usingOnClick = true;
            actualProps = {
                ...actualProps,
                onClick: () => this.setState({ showMenu: !showMenu })
            };
        }

        // Update the avatarClass based on certain conditions
        // =====================================================
        if (typeof className !== "undefined") avatarClass = `${avatarClass} ${className}`;
        if (usingLink || usingOnClick) avatarClass = `${avatarClass} clickable`;
        if (centralized === true) avatarClass = `${avatarClass} centralized`;

        // Update source based on certain condition
        // =====================================================
        if (pattern === true && source > 0 && source < 16) {
            type = "pattern";
            source = require(`../../media/patterns/dp_${source}.png`);
        } else if (dicebear) {
            type = "dicebear";
            source = sourceURL.length > 0 ? sourceURL : fn.avatar(source);
            avatarClass = `${avatarClass} dicebear`;
        } else if (image === true) {
            type = "image";
            source =
                _.get(source.split("/"), "[0]") === "static"
                    ? source
                    : require(`../../media/avatar/${source}`);
        } else if (typeof sourceURL !== "undefined" && sourceURL.length > 0) {
            type = "image";
            source = sourceURL;
        } else {
            type = "icon";
            source = "fas user";
        }

        // Update avatar size
        // =====================================================
        if (typeof size === "number" && size > 40) {
            let sizeStyle = { width: size, height: size };
            const { style } = this.props;

            if (shape === "circular") sizeStyle = { ...sizeStyle, borderRadius: size / 2 };
            if (typeof style === "object" && Object.keys(style).length > 0)
                sizeStyle = { ...sizeStyle, ...style };

            actualProps.style = sizeStyle;
        }

        if (usingLink) {
            return (
                <div ref={this._setRef} className={avatarClass} {...actualProps}>
                    <Node to={to}>
                        <AvatarContent type={type} source={source} usingLink={usingLink} />
                    </Node>
                    <Dropdown menu={menu} show={showMenu} animation="drop" right />
                </div>
            );
        } else {
            return (
                <Node ref={this._setRef} className={avatarClass} {...actualProps}>
                    <AvatarContent type={type} source={source} usingLink={usingLink} />
                    <Dropdown menu={menu} show={showMenu} animation="drop" right />
                </Node>
            );
        }
    }
}

const AvatarContent = props => {
    let node = "";
    const { type, source, usingLink } = props;

    switch (type) {
        default:
        case "icon":
            node = <Icon name={source} />;
            break;

        case "image":
        case "pattern":
        case "dicebear":
            node = <div style={{ background: `url(${source})` }} className="image" />;
            break;
    }

    return usingLink ? node : <div className="content">{node}</div>;
};

// Use this array to omit and get the actualProps
const omitProps = [
    "to",
    "menu",
    "className",
    "as",
    "size",
    "shape",
    "image",
    "source",
    "sourceURL",
    "pattern",
    "dicebear",
    "square",
    "rounded",
    "centralized"
];

export { Avatar };
