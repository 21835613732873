import { combineReducers } from "redux";
import data from "./data";
import site from "./site";
import menu from "./menu";
import request from "./request";
import permissions from "./permissions";

export default combineReducers({
    data,
    site,
    menu,
    request,
    permissions
});
