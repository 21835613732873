import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

class Pagination extends React.Component {
    constructor(props) {
        super(props);
        // this._onClick = this._onClick.bind(this);
        this.state = {
            buttonIndex: 0
        };
    }

    _onClick = (e, index, last) => {
        const { onClick, currentIndex } = this.props;
        // console.log(e, index, last);

        if (currentIndex === index) return false;
        if (typeof onClick === "function") onClick(e, index);

        if (typeof last !== "undefined") {
            let { buttonIndex } = this.state;

            last === true ? buttonIndex++ : buttonIndex--;
            this.setState({ buttonIndex });
        }
    };

    render() {
        let items = [];
        let { length, currentIndex = 0, limit = 5, size } = this.props;
        let containerClass = "ui pagination menu";

        for (let i = 1, index = 0; i <= length; i++, index++) {
            let onClick = this._onClick.bind(this, index);
            let className = "item";
            if (currentIndex === index) {
                onClick = null;
                className = "active item";
            }

            items.push(
                <div key={i} className={className} onClick={onClick}>
                    {i}
                </div>
            );
        }

        if (typeof size !== "undefined") containerClass = `${containerClass} ${size}`;

        // Pagination for the pagination buttons
        if (limit > 0) {
            const chunks = _.chunk(items, limit);
            const lastChunkIndex = chunks.length - 1;
            const { buttonIndex } = this.state;
            const { fn } = window;

            items = chunks[buttonIndex];
            if (buttonIndex < lastChunkIndex) {
                const index = items.length * (buttonIndex + 1);
                const onClick = this._onClick.bind(this, index, true, true);

                items.push(
                    <div key={fn.unique()} className="item" onClick={onClick}>
                        ...
                    </div>
                );
            }

            if (buttonIndex > 0) {
                const index = chunks[buttonIndex - 1].length * buttonIndex - 1;
                const onClick = this._onClick.bind(this, index, false, false);

                items.unshift(
                    <div key={fn.unique()} className="item" onClick={onClick}>
                        ...
                    </div>
                );
            }
        }

        return <div className={containerClass}>{items}</div>;
    }
}

Pagination.propTypes = {
    size: PropTypes.oneOf(["mini", "tiny", "small", "large", "huge", "bassic"]),
    length: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    currentIndex: PropTypes.number.isRequired
};

export { Pagination };
