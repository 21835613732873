import CONST from "../includes/Constants";

const defaultState = {
    alert: [],
    enableAds: true,
    progressBarStatus: false
};

export default (state = defaultState, action) => {
    const { payload, type } = action;

    switch (type) {
        case CONST.R.SET_PROGRESS_BAR:
            return {
                ...state,
                progressBarStatus: action.isOpen
            };

        case CONST.R.NEW_ALERT:
            return {
                ...state,
                alert: [
                    ...state.alert,
                    {
                        id: payload.id,
                        show: payload.show,
                        color: payload.color,
                        content: payload.content,
                        duration: payload.duration,
                        animation: payload.animation
                    }
                ]
            };

        case CONST.R.HIDE_ALERT:
            return {
                ...state,
                alert: payload
            };

        case CONST.R.TRUNCATE_ALERT:
            return {
                ...state,
                alert: []
            };

        default:
            return state;
    }
};
