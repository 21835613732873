import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Heading, Carousel } from ".";

class Header extends React.Component {
    state = { loading: true, headers: [] };

    componentDidMount() {
        window.fn.get("/headers").then((o) => {
            this.setState({
                loading: false,
                headers: _.isArray(o.data) && o.data.length > 0 ? o.data : [],
            });
        });
    }

    render() {
        const { loading, headers = [] } = this.state;
        const { title, subtitle, featured = false } = this.props;
        const omit = ["width", "title", "height", "featured", "subtitle", "settings"];
        const rest = _.omit(this.props, omit);

        if (featured === true) {
            return <Carousel width="100%" height={540} data={headers} loading={loading} {...rest} />;
        }

        return (
            <div className="aibim header">
                <Heading as="h2" text={title} subtitle={subtitle} />
            </div>
        );
    }
}

Header.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    featured: PropTypes.bool,
};

export { Header };
