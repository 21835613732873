import CONST from "../includes/Constants";

const initialState = {
    menuArray: [],
    currentPath: "/"
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CONST.R.SET_MENU_ARRAY:
            return {
                ...state,
                menuArray: payload
            };

        case CONST.R.CLEAR_MENU_ARRAY:
            return {
                ...state,
                menuArray: payload
            };

        case CONST.R.SET_CURRENT_PAGE:
            return {
                ...state,
                currentPath: payload
            };

        default:
            return state;
    }
};
