import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import { Icon } from "..";

class Message extends React.Component {
    render() {
        const { fn } = window;
        const { text, icon, title, color, markdown } = this.props;
        const rest = _.omit(this.props, [
            "text",
            "icon",
            "title",
            "color",
            "markdown",
            "className"
        ]);
        const iconClass = typeof icon !== "undefined" ? "with-icon" : "";

        const textOutput = markdown === true ? <ReactMarkdown source={text} /> : text;

        return (
            <div className={`om ${iconClass} ${color} message`} {...rest}>
                {icon && (
                    <div className="icon">
                        <div className="orb">
                            <Icon icon={icon} />
                        </div>
                    </div>
                )}
                <div className="text">
                    {fn.renderif(
                        typeof title !== "undefined" && title.length > 0,
                        <div className="ui header">
                            {title}
                            <div className="sub header">{textOutput}</div>
                        </div>,
                        textOutput
                    )}
                </div>
            </div>
        );
    }
}

Message.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string,
    title: PropTypes.string,
    markdown: PropTypes.bool
};

export { Message };
