import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

class ReactHelmet extends React.Component {
    render() {
        const { title, meta } = this.props;
        const hasMeta = typeof meta !== "undefined" && meta.length > 0;

        return (
            <Helmet>
                <title>{title || "My Application"}</title>
                {hasMeta &&
                    meta.map((props, k) => {
                        const metaType = props.metaType;
                        const realProps = _.omit(props, ["metaType"]);

                        switch (metaType) {
                            case "link":
                                return <link key={k} {...realProps} />;

                            default:
                            case "meta":
                                return <meta key={k} {...realProps} />;
                        }
                    })}
            </Helmet>
        );
    }
}

ReactHelmet.propTypes = {
    meta: PropTypes.array,
    title: PropTypes.string
};

export { ReactHelmet };
