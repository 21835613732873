import _ from "lodash";
import parse from "url-parse";
import React from "react";
import ReactMarkdown from "react-markdown";
import { withRouter, Link } from "react-router-dom";

class MarkdownComponent extends React.Component {
    /**
     * Renders the link for the Markdown. What this method does is it detects
     * whether the URL passed is local. If the URL is local, return Link component
     * instead.
     *
     * @return  object
     * @since   0.1
     */
    _linkRenderer = props => {
        let actualProps = _.omit(props, ["href", "children"]);
        const { href, children } = props;
        const { target = "_blank" } = this.props;

        // If no URL is present
        if (typeof href === "undefined" && href.length < 1) return { children };

        const localURL = parse(window.location.href, true);
        const parsedURL = parse(href, true);

        let { extensions = ["pdf", "png", "jpg", "jpeg"] } = this.props;
        const ext = href.split(".").pop() || "";
        const hasExtension = extensions.indexOf(ext.toLowerCase()) > -1;

        const hrefHostname = _.get(parsedURL, "hostname");
        const localHostname = _.get(localURL, "hostname");
        const isLocal = hrefHostname === localHostname;

        if (isLocal && !hasExtension) {
            return <Link to={href}>{children}</Link>;
        } else {
            if (target === "_blank") {
                actualProps = {
                    ...actualProps,
                    rel: "noopener noreferrer",
                    target: "_blank"
                };
            }

            return (
                <a href={href} {...actualProps}>
                    {children}
                </a>
            );
        }
    };

    render() {
        let { inline, renderers } = this.props;
        let props = _.omit(this.props, ["inline", "renderers"]);

        renderers = {
            link: this._linkRenderer,
            ...renderers
        };

        if (inline === true) {
            props.disallowedTypes = ["paragraph"];
            props.unwrapDisallowed = true;
        }

        return <ReactMarkdown renderers={renderers} {...props} />;
    }
}

const Markdown = withRouter(props => <MarkdownComponent {...props} />);

export { Markdown };
