import _ from "lodash";
import fn from "./includes/Functions";
import CONST from "./includes/Constants";
import React from "react";
import routes from "./routes.json";
import { connect } from "react-redux";
import { HandleProgressBar, PrivateRoute } from "./components";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import * as actions from "./actions";

import ReactGA from "react-ga";
ReactGA.initialize("UA-150768187-1");
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends React.Component {
    constructor(props) {
        super(props);

        // Set global variables
        window.fn = fn;
        window.func = fn;
        window.CONST = CONST;
    }

    _renderRoutes = (routes, parentComponent, childAuth) => {
        return routes.map((route, key) => {
            let routeDOM = [];
            let props = _.omit(route, ["to", "path", "children", "component"]);
            let { to, path, component, children, auth } = route;

            // When this is the submenu, we need to treat it well. Sometimes we use
            // parameterized path on the same component.
            if (typeof parentComponent !== "undefined" && typeof component === "undefined")
                component = parentComponent;

            // This piece of route doesn't have any path
            if (typeof to === "undefined" && typeof path === "undefined") return false;
            if (typeof props.exact === "undefined") props.exact = true;

            // Check if this route has a component defined
            const hasComponent = typeof component !== "undefined";

            // For children, who have no auth parameter set, but the parent has it..
            if (typeof auth === "undefined" && typeof childAuth === "boolean")
                props.auth = childAuth;

            // Now, let's deal with the path. By default, we assume that route.to is the path
            if (hasComponent) {
                const componentProps = require(`./pages/${component}`).default;

                if (typeof path !== "undefined") {
                    if (typeof path === "object" && path.constructor === Array) {
                        path.map((path, i) => {
                            props.path = path;
                            return (routeDOM = [
                                ...routeDOM,
                                <PrivateRoute key={i} component={componentProps} {...props} />
                            ]);
                        });
                    } else {
                        props.path = path;
                        routeDOM = [
                            ...routeDOM,
                            <PrivateRoute key={key} component={componentProps} {...props} />
                        ];
                    }
                } else {
                    props.path = to;
                    routeDOM = [
                        ...routeDOM,
                        <PrivateRoute key={key} component={componentProps} {...props} />
                    ];
                }
            }

            // Then we handle the children path
            if (typeof children !== "undefined") {
                routeDOM = [...routeDOM, this._renderRoutes(children, component, auth)];
            }

            return routeDOM;
        });
    };

    componentDidMount() {
        this.props.setMenuArray();
    }

    render() {
        if (routes.length < 1) {
            return (
                <div>
                    Routes are unavailable. Please add some routes in <code>src/routes.js</code>
                </div>
            );
        } else {
            return (
                <BrowserRouter>
                    <>
                        <HandleProgressBar />

                        <Switch>
                            {this._renderRoutes(routes)}
                            <PrivateRoute component={NotFound} />
                        </Switch>
                    </>
                </BrowserRouter>
            );
        }
    }
}

const NotFound = () => <Redirect to="/404" />;
const mapStateToProps = state => state;

export default connect(
    mapStateToProps,
    actions
)(App);
