import _ from "lodash";
import * as actions from "../actions";
import React from "react";
import { connect } from "react-redux";
import { Loader } from "semantic-ui-react";
import { TopBar, AdPopup, MainMenu, PushMenu, ReactHelmet, FloatingMenu } from ".";

import "semantic-ui-css/semantic.min.css";
import "../styles/default.scss";

class GlobalRedux extends React.Component {
    constructor(props) {
        super(props);

        this.topContainer = null;
        this.state = {
            init: false,
            blur: false,
            ready: false,
            popup: null,
            sticky: false,
            menuOpen: false,
        };
    }

    static getDerivedStateFromProps(props, prevState) {
        const { menuArray = [] } = props.menu;
        const { banks = [], settings = {} } = props.data;

        if (prevState.init === false) {
            props.setProgressBar(true);
            return { init: true };
        }

        const hasMenu = menuArray.length > 0;
        const hasBanks = banks.length > 0;
        const hasSettings = Object.keys(settings).length > 0;

        if (!hasMenu) props.setMenuArray();
        if (!hasBanks) props.getMemberBanks();
        if (!hasSettings) props.getSettings();
        if (hasMenu && hasSettings) return { ready: true };

        return null;
    }

    componentDidUpdate() {
        const { fn } = window;

        fn.hideProgressBar(this.props);
        window.addEventListener("scroll", this._stickyHandler);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this._stickyHandler);
    }

    _stickyHandler = (e) => {
        const y = _.get(e, "srcElement.documentElement.scrollTop");
        const topHeight = _.get(this, "topContainer.clientHeight");
        const offset = topHeight / 2;
        const sticky = typeof topHeight === "number" && y > offset;

        if (typeof y === "number") this.setState({ sticky });
    };

    _toggleMenu = () => {
        const { menuOpen } = this.state;
        const negateStatus = !menuOpen;

        this.setState({ menuOpen: negateStatus, blur: negateStatus });
        negateStatus
            ? document.body.classList.add("no-overflow-x")
            : document.body.classList.remove("no-overflow-x");
    };

    _adPopupClose = () => {
        this.setState({ popup: null });
        this.props.enableAds(false);
    };

    render() {
        const { fn, CONST } = window;
        const { blur, ready, sticky, menuOpen } = this.state;
        const { title, children } = this.props;
        const adminURL = CONST.API_URL;
        const blurClass = blur ? " blur" : "";

        const topHeight = _.get(this, "topContainer.clientHeight");
        const pageTitle = title ? `${title} – ${CONST.SITENAME}` : CONST.SITENAME;
        const topContainerClass = sticky ? "sticky top container" : "top container";

        let childrenStyle = {};
        if (sticky === true) {
            childrenStyle = {
                marginTop: topHeight,
            };
        }

        if (ready === true) {
            const menu = _.get(this.props, "menu.menuArray", []);
            const settings = _.get(this.props, "data.settings", {});
            const { social, popup } = settings;

            // Check for popup
            const { enabled: popupEnabled = false, photo: popupPhoto = null } = popup || {};
            const popupPhotoURL = _.get(popupPhoto, "url", null);
            const hasPopup = popupEnabled && popupPhotoURL !== null;

            const meta = [
                {
                    type: "meta",
                    name: "description",
                    value:
                        "AIBIM is a dynamic, visible, responsive and effective organisation representing the voice of Islamic Finance industry underpinning the value based intermediation thrust in serving the community",
                },
                {
                    type: "meta",
                    name: "mywakaf-initiative",
                    value:
                        "myWakaf is an inclusive economic instrument that based on philanthropic value in order to achieve its objective; best serve all members of society particularly the underserved, to have an access of quality essential services and assistant in fulfilling their needs towards shared prosperity.",
                },
                {
                    type: "meta",
                    name: "about-us",
                    value:
                        "The Association of Islamic Banking and Financial Institutions Malaysia (AIBIM) was established in 1995 as the Association of Interest-Free Banking Institutions Malaysia.",
                },
                ...fn.getMeta(),
            ];

            return (
                <>
                    <ReactHelmet meta={meta} title={pageTitle} />
                    <PushMenu menu={menu} social={social} menuOpen={menuOpen} toggleMenu={this._toggleMenu} />
                    <FloatingMenu settings={settings} />
                    <div className={`main container${blurClass}`}>
                        <div className={topContainerClass}>
                            <div ref={(node) => (this.topContainer = node)}>
                                <TopBar
                                    fx="hamburger--slider"
                                    social={social}
                                    menuOpen={menuOpen}
                                    toggleMenu={this._toggleMenu}
                                />
                            </div>
                            <MainMenu menu={menu} />
                        </div>

                        <div className="body" style={childrenStyle}>
                            {children}
                        </div>
                    </div>

                    {hasPopup && (
                        <AdPopup
                            open={hasPopup}
                            image={`${adminURL}${popupPhotoURL}`}
                            onClose={this._adPopupClose}
                            closeOnEscape={true}
                            closeOnDimmerClick={true}
                        />
                    )}
                </>
            );
        } else {
            const errorStyle = {
                width: "100%",
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            };

            return (
                <div style={errorStyle}>
                    <Loader />
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps, actions)(GlobalRedux);
