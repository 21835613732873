import React from "react";
import { Tab } from "semantic-ui-react";

class Tabs extends React.Component {
    render() {
        const { props } = this;
        return (
            <div className="om tabs overflow">
                <Tab {...props} />
            </div>
        );
    }
}

export { Tabs };
