import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { Field, Heading, Markdown } from "..";

class Fields extends React.Component {
    _renderGroup = (props, key) => {
        const { custom = false, fields, label, heading, subtitle, guide } = props;
        const Group = custom ? "div" : Form.Group;
        props = _.omit(props, [
            "custom",
            "heading",
            "subtitle",
            "fields",
            "label",
            "guide",
            "group"
        ]);

        if (typeof fields !== "undefined") {
            if (custom === true) props.className = "custom fields";

            return (
                <React.Fragment key={key}>
                    {heading && typeof heading === "object" && <Heading {...heading} />}
                    {heading && typeof heading !== "object" && (
                        <Heading
                            as="h5"
                            style={{ width: "100%", display: "block", flex: 1 }}
                            text={heading}
                            subtitle={subtitle}
                        />
                    )}
                    <Group widths={fields.length} {...props}>
                        {custom && label && (
                            <label style={{ paddingBottom: 5 }}>
                                {label}
                                {guide && (
                                    <span className="guide">
                                        <Markdown source={guide} />
                                    </span>
                                )}
                            </label>
                        )}

                        {fields.map((itemProps, i) => {
                            itemProps.useField = true;
                            if (typeof itemProps.indent === "number" && itemProps.indent > 1) {
                                return (
                                    <div key={i} style={{ paddingLeft: 16 * itemProps.indent }}>
                                        <Field {...itemProps} />
                                    </div>
                                );
                            }

                            return <Field key={i} {...itemProps} />;
                        })}
                    </Group>
                </React.Fragment>
            );
        }
    };

    render() {
        const { fields } = this.props;
        const hasFields = typeof fields !== "undefined" && fields.length > 0;

        return (
            hasFields &&
            fields.map((props, key) => {
                const { useField, group, separator } = props;
                props = { ...props, useField: typeof useField === "undefined" ? true : useField };
                props = _.omit(props, ["group", "separator"]);

                if (typeof group !== "undefined") {
                    return this._renderGroup(group, key);
                } else if (typeof separator !== "undefined" && separator === true) {
                    return <div key={key} className="ui horizontal divider" />;
                } else {
                    return <Field key={key} {...props} />;
                }
            })
        );
    }
}

Fields.propTypes = {
    fields: PropTypes.array.isRequired,
    callback: PropTypes.func
};

export { Fields };
