import React from "react";
import PropTypes from "prop-types";

class CardCover extends React.Component {
    render() {
        const { backgroundImage, overlay } = this.props;

        return (
            <div className="cover" style={{ backgroundImage: `url(${backgroundImage})` }}>
                {overlay === true && <div className="overlay" />}
            </div>
        );
    }
}

CardCover.propTypes = {
    overlay: PropTypes.bool,
    backgroundImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export { CardCover };
