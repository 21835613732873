import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

class Card extends React.Component {
    render() {
        let { props } = this;
        let { className = "", children } = props;
        let actualProps = _.omit(props, ["className", "children"]);
        if (typeof className !== "undefined" && className.length > 0) className = ` ${className}`;

        return (
            <div className={`card${className}`} {...actualProps}>
                {children}
            </div>
        );
    }
}

Card.propTypes = {
    className: PropTypes.string
};

export { Card };
