import CONST from "../includes/Constants";

export default (state = {}, action) => {
    switch (action.type) {
        case CONST.R.SAMPLE_REQUEST:
            return {
                ...state,
                sampleRequest: action.payload
            };

        default:
            return state;
    }
};
