import _ from "lodash";
import CONST from "../includes/Constants";

const defaultState = {
    user: {},
    banks: [],
    settings: {},
};

export default (state = defaultState, { type, payload }) => {
    switch (type) {
        case CONST.R.GET_INSTRUMENT:
            return {
                ...state,
                instruments: payload,
            };

        case CONST.R.SET_DOMAINS:
            return {
                ...state,
                domains: payload,
            };

        case CONST.R.GET_USER_DATA:
            return {
                ...state,
                user: payload,
            };

        case CONST.R.SET_DISABILITY_TYPES:
            return {
                ...state,
                disabilityTypes: payload,
            };

        case CONST.R.GET_SETTINGS:
            return {
                ...state,
                settings: payload,
            };

        case CONST.R.ENABLE_ADS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    popup: {
                        ..._.get(state, "settings.popup", {}),
                        enabled: payload,
                    },
                },
            };

        case CONST.R.GET_MEMBER_BANKS:
            return {
                ...state,
                banks: payload,
            };

        default:
            return state;
    }
};
