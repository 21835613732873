import _ from "lodash";
import * as actions from "../actions";
import React from "react";
import { connect } from "react-redux";
import { Loader } from "semantic-ui-react";
import { Global, Albums, Header, Content, CardContent, SectionFooter } from "../components";

class Gallery extends React.Component {
    state = {
        init: false,
        data: [],
        folder: "",
        loading: true,
    };

    static getDerivedStateFromProps(props, prevState) {
        const path = _.get(props, "match.path", "");
        const folder = _.get(path.split("/"), "[1]", "");

        if (prevState.folder !== folder) return { data: [], init: false, folder };
        return null;
    }

    componentDidUpdate() {
        const { fn } = window;
        const { init, data = [], folder } = this.state;

        if (data.length < 1 && !init) {
            const endpoint = _.get({ photos: "/photos", videos: "/videos" }, folder, null);
            if (endpoint !== null) {
                fn.get(endpoint).then((o) => {
                    const { data } = o;
                    this.setState({
                        data: _.isArray(data) && data.length > 0 ? data : [],
                        init: true,
                        loading: false,
                    });
                });
            }
        }
    }

    render() {
        const { settings = {} } = this.props.data;
        const { footnote = {} } = settings;

        const { fn } = window;
        const { data, folder, loading } = this.state;

        const slug = _.get(this.props, "match.params.slug", "");
        const isAlbum = slug === "";

        let title = "Gallery";
        let subtitle = "";
        if (!isAlbum) {
            const index = _.findIndex(data, (o) => o.slug === slug);
            const albumData = _.get(data, `[${index}]`, {});
            const { name = "Gallery", date, created_at } = albumData;
            const albumDate = date || created_at;

            title = name;
            subtitle = albumDate ? fn.date(albumDate) : "";
        }

        return (
            <Global title={title}>
                <Header title={title} subtitle={subtitle} />

                <Content footerLeft={footnote.left} footerRight={footnote.right}>
                    <CardContent>
                        {fn.renderif(
                            loading,
                            <Loader active inline="centered" />,
                            <Albums slug={slug} list={data} albums={isAlbum} folder={folder} />
                        )}
                    </CardContent>

                    <SectionFooter settings={settings} />
                </Content>
            </Global>
        );
    }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps, actions)(Gallery);
