import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Transition } from "semantic-ui-react";
import { Icon, Link } from "../";

class Dropdown extends React.Component {
    render() {
        let {
            menu = [],
            show = false,
            right = false,
            pointer = false,
            position,
            duration = 300,
            animation = "fade down",
            className = "om left dropdown"
        } = this.props;

        if (typeof position !== "undefined") className = `om ${position} dropdown`;
        if (right === true) className = "om right dropdown";
        if (pointer === true) className = `${className} pointer`;

        return (
            _.isArray(menu) &&
            menu.length > 0 && (
                <Transition visible={show} animation={animation} duration={duration}>
                    <div className={className}>
                        <ul>
                            {menu.map((props, key) => {
                                const { label, icon, onClick, to, color } = props;
                                const Node = typeof to !== "undefined" ? Link : "div";
                                const clickable =
                                    typeof onClick !== "undefined" || typeof to !== "undefined";

                                // Props
                                const rest = _.omit(props, ["label", "icon", "to"]);
                                const nodeProps = typeof to !== "undefined" ? { to } : {};

                                // Item class
                                let itemClass = clickable ? "clickable item" : " item";
                                if (typeof color !== "undefined")
                                    itemClass = `${color} ${itemClass}`;

                                if (typeof to !== "undefined" && to.length > 0) {
                                    return (
                                        <li key={key}>
                                            <Node {...nodeProps}>
                                                <div className={itemClass} {...rest}>
                                                    <div className="icon">
                                                        <Icon name={icon} />
                                                    </div>
                                                    {label}
                                                </div>
                                            </Node>
                                        </li>
                                    );
                                } else {
                                    return (
                                        <li key={key}>
                                            <div className={itemClass} {...rest}>
                                                <div className="icon">
                                                    <Icon name={icon} />
                                                </div>
                                                {label}
                                            </div>
                                        </li>
                                    );
                                }
                            })}
                        </ul>
                    </div>
                </Transition>
            )
        );
    }
}

Dropdown.propTypes = {
    menu: PropTypes.array.isRequired,
    show: PropTypes.bool,
    right: PropTypes.bool,
    pointer: PropTypes.bool,
    duration: PropTypes.number,
    position: PropTypes.string,
    animation: PropTypes.string,
    className: PropTypes.string
};

export { Dropdown };
