import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Icon, Link } from "../";

class Button extends React.Component {
    render() {
        let {
            to,
            size = "medium",
            color,
            text,
            icon,
            pull,
            type = "button",
            style,
            basic,
            variant,
            loading = false,
            fluid = false,
            paddingTop,
            transparent,
            circular = false,
            display = true
        } = this.props;

        if (circular === true) variant = "circular";

        let Element = `${type}`;
        let fluidClass = fluid === true ? "fluid" : "";
        let buttonStyle = {};

        let basicClass = basic === true ? "basic" : "";
        let loadingClass = loading === true ? "loading" : "";
        let transparentClass = transparent === true ? "transparent" : "";
        let actualProps = _.omit(this.props, [
            "size",
            "color",
            "text",
            "icon",
            "pull",
            "type",
            "style",
            "variant",
            "loading",
            "fluid",
            "rounded",
            "paddingTop",
            "transparent",
            "circular",
            "basic",
            "display"
        ]);

        if (typeof pull !== "undefined" && pull === "right")
            buttonStyle = { marginRight: 0, marginLeft: 3 };

        let button = null;
        let className = `om ui ${fluidClass} ${color} ${size} ${variant} ${loadingClass} ${basicClass} ${transparentClass} button`;
        if (typeof to !== "undefined" && to.length > 0) {
            button = (
                <Link to={to}>
                    <div
                        style={{ ...style, ...buttonStyle }}
                        disabled={loading === true}
                        className={className}
                        {...actualProps}
                    >
                        {icon && (
                            <Icon name={icon} className={text ? "buttonicon" : "buttonicon only"} />
                        )}
                        {text}
                    </div>
                </Link>
            );
        } else {
            button = (
                <Element
                    style={{ ...style, ...buttonStyle }}
                    disabled={loading === true}
                    className={className}
                    {...actualProps}
                >
                    {icon && (
                        <Icon name={icon} className={text ? "buttonicon" : "buttonicon only"} />
                    )}
                    {text}
                </Element>
            );
        }

        // if ( typeof to !== "undefined" && to.length > 0 ){
        //     button = (
        //         <Link
        //             style={{ ...style, ...buttonStyle }}
        //             disabled={loading === true}
        //             className={className}
        //             {...actualProps}
        //         >
        //             {icon && <Icon name={icon} className={text ? "buttonicon" : "buttonicon only"} />}
        //             {text}
        //         </Link>
        //     );
        // } else {
        //     button = (
        //         <Element
        //             style={{ ...style, ...buttonStyle }}
        //             disabled={loading === true}
        //             className={`om ui ${fluidClass} ${color} ${size} ${variant} ${loadingClass} ${basicClass} ${transparentClass} button`}
        //             {...actualProps}
        //         >
        //             {icon && <Icon name={icon} className={text ? "buttonicon" : "buttonicon only"} />}
        //             {text}
        //         </Element>
        //     );
        // }

        if (pull || paddingTop) return <div style={{ textAlign: pull, paddingTop }}>{button}</div>;

        return display && button;
    }
}

Button.propTypes = {
    to: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.string,
    type: PropTypes.oneOf(["button", "a", "div"]),
    pull: PropTypes.oneOf(["left", "center", "right"]),
    size: PropTypes.oneOf(["mini", "tiny", "small", "medium", "large", "big", "huge", "massive"]),
    style: PropTypes.object,
    basic: PropTypes.bool,
    color: PropTypes.string,
    fluid: PropTypes.bool,
    loading: PropTypes.bool,
    variant: PropTypes.oneOf(["rounded", "circular"]),
    paddingTop: PropTypes.number,
    transparent: PropTypes.bool,
    circular: PropTypes.bool,
    display: PropTypes.bool
};

export { Button };
