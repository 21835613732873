import _ from "lodash";
import api from "../api";
import fn from "../includes/Functions";
import CONST from "../includes/Constants";
const { R } = CONST;

/**
 * A sample request made from backend's API.
 *
 * @return  object
 * @since   1.0
 */
export const sampleRequest = () => (dispatch, getState) => {
    fn.tokenize("test").then(async (token) => {
        await api.get("/test", { params: { token } }).then((o) =>
            dispatch({
                type: R.SAMPLE_REQUEST,
                payload: o.data,
            })
        );
    });
};

/**
 * Set progress bar.
 *
 * @param   boolean     isOpen  Set to true if we're showing the progress bar.
 *
 * @return  object
 * @since   1.0
 */
export const setProgressBar = (isOpen) => ({
    type: R.SET_PROGRESS_BAR,
    isOpen: isOpen,
});

/**
 * Set the current page.
 *
 * @param   string     path     The page path (usually the root).
 *
 * @return  object
 * @since   1.0
 */
export const setCurrentPage = (path) => ({
    type: R.SET_CURRENT_PAGE,
    payload: path,
});

/**
 * Set alert.
 *
 * @param   string      content     The alert content.
 * @param   string      color       The alert color.
 * @param   integer     duration    The time before the alert disappears. Set to 0 to make it stay.
 * @param   integer     tempo       The duration for the alert animation.
 * @param   string      animation   The animation type.
 *
 * @return  object
 * @since   0.1
 */
export const newAlert = (
    content,
    color,
    animation = "fade left",
    duration = CONST.ALERT_DURATION,
    tempo = CONST.ALERT_TEMPO
) => {
    return {
        type: R.NEW_ALERT,
        payload: {
            id: fn.unique(),
            show: true,
            color,
            tempo,
            content,
            duration,
            animation,
        },
    };
};

/**
 * Hide unused alert popup from must associate with fn.hideAlert() for efficiency.
 *
 * @param   object  array   New data.
 *
 * @return  object
 * @since   0.1
 */
export const hideAlert = (data) => {
    return {
        type: R.HIDE_ALERT,
        payload: data,
    };
};

/**
 * Delete all alerts.
 *
 * @return  object
 * @since   0.1
 */
export const truncateAlert = () => {
    return {
        type: R.TRUNCATE_ALERT,
        payload: [],
    };
};

/**
 * Set the menu array.
 *
 * @return  object
 * @since   0.1
 */
export const setMenuArray = () => async (dispatch) => {
    return await api.get("/menu").then((o) => {
        const { menu = [] } = o.data;
        if (_.isArray(menu) && menu.length > 0) {
            dispatch({ type: R.SET_MENU_ARRAY, payload: menu });
        }
    });
};

/**
 * Clear menu array.
 *
 * @return  object
 * @since   0.1
 */
export const clearMenuArray = () => {
    return {
        type: R.CLEAR_MENU_ARRAY,
        payload: [],
    };
};

/**
 * Turn on/of the popup ads.
 *
 * @since   0.1
 */
export const enableAds = (bool) => {
    return {
        type: R.ENABLE_ADS,
        payload: bool,
    };
};

/**
 * Get settings.
 *
 * @see     GlobalRedux.js
 * @return  object
 * @since   0.1
 */
export const getSettings = () => async (dispatch) => {
    return await api.get("/settings").then((o) => {
        let { data } = o;
        if (_.isObject(data)) {
            // Process the settings props to make it key-value pair
            if (_.isArray(data.settings)) {
                let { settings = [], contact = [] } = data;
                const contacts = _.clone(contact);

                if (_.isArray(settings) && settings.length > 0) {
                    settings.map(({ name, value }) => {
                        if (name === "footnote_right") {
                            value = JSON.parse(value);
                        }

                        data[name] = value;
                        return true;
                    });
                }

                if (_.isArray(contacts) && contacts.length > 0) {
                    data.contact = {};
                    contacts.map(({ name, value }) => {
                        data.contact[name] = value;
                        return true;
                    });
                }
            }

            dispatch({ type: R.GET_SETTINGS, payload: data });
        }
    });
};

/**
 * Get bank members.
 *
 * @see     GlobalRedux.js
 * @return  object
 * @since   0.1
 */
export const getMemberBanks = () => async (dispatch) => {
    return await api.get("/member-banks").then((o) => {
        const { data = [] } = o;
        dispatch({ type: R.GET_MEMBER_BANKS, payload: data });
    });
};
