import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import {
    Bar,
    Line,
    Cell,
    XAxis,
    YAxis,
    Legend,
    Tooltip,
    ComposedChart,
    CartesianGrid,
    ResponsiveContainer
} from "recharts";

class Chart extends React.Component {
    render() {
        const { fn } = window;

        const {
            data,
            height = 500,
            components,

            xAxis = true,
            yAxis = true,
            legend = true,
            tooltip = true,
            xAxisProps,
            yAxisProps,
            legendProps,
            tooltipProps
        } = this.props;

        // Blend default props with the customs
        const _xAxisProps = {
            tick: { fill: "#485e72" },
            dataKey: "name",
            axisLine: { stroke: "#eaeef0" },
            tickLine: false,
            ...xAxisProps
        };
        const _yAxisProps = {
            tick: { fill: "#485e72" },
            axisLine: { stroke: "#eaeef0" },
            tickLine: false,
            ...yAxisProps
        };
        const _legendProps = {
            verticalAlign: "bottom",
            ...legendProps
        };
        const _tooltipProps = {
            cursor: false,
            formatter: (v, n, p) => [
                <>
                    <small>RM</small>
                    {fn.numberFormat(p.value)}
                </>,
                p.name
            ],
            contentStyle: {
                color: "#485e72",
                padding: 10,
                background: "#fff",
                lineHeight: 1,
                borderWidth: 0,
                borderRadius: 5
            },
            ...tooltipProps
        };

        return (
            <ResponsiveContainer height={height}>
                <ComposedChart data={data}>
                    <CartesianGrid vertical={false} horizontal={true} stroke="#eaeef0" />

                    {xAxis === true && <XAxis {..._xAxisProps} />}
                    {yAxis === true && <YAxis {..._yAxisProps} />}
                    {legend === true && <Legend {..._legendProps} />}
                    {tooltip === true && <Tooltip {..._tooltipProps} />}

                    {components.map((v, i) => {
                        const { chartType, colors } = v;
                        const actualProps = _.omit(v, ["chartType"]);

                        switch (chartType) {
                            case "line":
                                return <Line key={i} {...actualProps} />;

                            default:
                            case "bar":
                                return (
                                    <Bar key={i} {...actualProps}>
                                        {typeof colors !== "undefined" &&
                                            colors.length > 0 &&
                                            data.map((e, k) => (
                                                <Cell key={k} fill={colors[k % colors.length]} />
                                            ))}
                                    </Bar>
                                );
                        }
                    })}
                </ComposedChart>
            </ResponsiveContainer>
        );
    }
}

Chart.propTypes = {
    data: PropTypes.array,
    height: PropTypes.number,
    components: PropTypes.array,

    xAxis: PropTypes.bool,
    yAxis: PropTypes.bool,
    legend: PropTypes.bool,
    tooltip: PropTypes.bool,
    xAxisProps: PropTypes.object,
    yAxisProps: PropTypes.object,
    legendProps: PropTypes.object,
    tooltipProps: PropTypes.object
};

export { Chart };
