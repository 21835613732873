import _ from "lodash";
import React from "react";
import { Grid } from "semantic-ui-react";
import { Card, Markdown } from ".";

class Content extends React.Component {
    render() {
        const props = _.omit(this.props, ["children", "footerLeft", "footerRight"]);
        let { children, footerLeft, footerRight } = this.props;

        if (typeof footerLeft !== "undefined" && _.isArray(footerLeft))
            footerLeft = footerLeft.join(" &bull; ");
        if (typeof footerRight !== "undefined" && _.isArray(footerRight))
            footerRight = footerRight.join(" &bull; ");

        return (
            <div className="content container" {...props}>
                <Card style={{ borderRadius: 5, marginBottom: 0 }}>{children}</Card>

                {(footerLeft || footerRight) && (
                    <div className="footnote">
                        <Grid stackable>
                            <Grid.Column width={8}>
                                <Markdown source={footerLeft} escapeHtml={true} />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign="right">
                                <Markdown source={footerRight} escapeHtml={true} />
                            </Grid.Column>
                        </Grid>
                    </div>
                )}
            </div>
        );
    }
}

export { Content };
