import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import { Markdown } from "..";
import { TextArea, Form, Checkbox, Select, Input, Radio } from "semantic-ui-react";

const Label = ({ id, label, guide, required }) => (
    <label htmlFor={id}>
        {label}
        {required === true && <span className="required">*</span>}
        {guide && (
            <div className="guide">
                <Markdown source={guide} />
            </div>
        )}
    </label>
);

class Field extends React.Component {
    render() {
        const { fn } = window;
        const { id, type, label, value, guide, useField, required = false, children } = this.props;
        const props = _.omit(this.props, ["type", "label", "guide", "useField", "key", "children"]);
        const labelProps = { id, label, guide, required };

        let inputHTML = "";

        switch (type) {
            case "textarea":
                inputHTML = (
                    <>
                        {label && <Label {...labelProps} />}
                        <TextArea {...props} />
                    </>
                );
                break;

            case "checkbox":
                inputHTML = <Checkbox label={label} {...props} />;
                break;

            case "radio":
                inputHTML = <Radio label={label} {...props} />;
                break;

            case "select":
                inputHTML = (
                    <>
                        {label && <Label {...labelProps} />}
                        <Select label={label} {...props} />
                    </>
                );
                break;

            default:
                inputHTML = (
                    <>
                        {label && <Label {...labelProps} />}
                        {fn.renderif(
                            typeof props.mask !== "undefined",
                            <InputMask type={type} value={value} {...props} />,
                            <Input type={type} value={value} {...props}>
                                {children}
                            </Input>
                        )}
                    </>
                );
                break;
        }

        if (useField === true) return <Form.Field>{inputHTML}</Form.Field>;

        return inputHTML;
    }
}

Field.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    guide: PropTypes.string,
    required: PropTypes.bool
};

export { Field };
