import _ from "lodash";
import React from "react";
import { Popup } from ".";

class AdPopup extends React.Component {
    render() {
        const { fn } = window;
        const props = _.omit(this.props, ["size", "className", "image", "children"]);
        const { size = "tiny", image, children } = this.props;

        if (typeof children !== "undefined" || typeof image !== "undefined") {
            const className = image !== "" ? "poster image" : "poster";

            return (
                <Popup size={size} className={className} {...props}>
                    {fn.renderif(children, children, <img src={image} alt="promo" />)}
                </Popup>
            );
        } else {
            return "";
        }
    }
}

export { AdPopup };
