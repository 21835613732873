import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Modal } from "semantic-ui-react";
import { Button } from "..";

class Popup extends React.Component {
    render() {
        let { buttons, children, onClose } = this.props;
        let rest = _.omit(this.props, ["buttons", "children"]);
        let closeStyle = {
            color: "#fff",
            position: "absolute",
            fontSize: 16,
            textAlign: "center",
            width: "100%",
            paddingTop: 5,
            paddingBottom: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        };

        return (
            <Modal dimmer="blurring" {...rest}>
                <Modal.Content>{children}</Modal.Content>
                {buttons && buttons.length > 0 && (
                    <Modal.Actions>
                        {buttons.map((props, k) => (
                            <Button key={k} {...props} />
                        ))}
                    </Modal.Actions>
                )}
                <div style={closeStyle}>
                    <span style={{ cursor: "pointer" }} onClick={onClose}>
                        Close
                    </span>
                </div>
            </Modal>
        );
    }
}

Popup.propTypes = {
    size: PropTypes.oneOf(["mini", "tiny", "small", "large", "fullscreen"]),
    open: PropTypes.bool,
    buttons: PropTypes.array,
    onClose: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export { Popup };
