import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

class Logo extends React.Component {
    _aspectRatio = (x, dimen = "width") => {
        let dw = 154;
        let dh = 107;
        let calc = 0;

        // adjusted height = <user-chosen width> * original height / original width
        // adjusted width = <user-chosen height> * original width / original height
        if (dimen.toLowerCase() === "height") calc = x * (dh / dw);
        else calc = x * (dw / dh);

        return Math.round(calc);
    };

    render() {
        let { props } = this;
        let { width, height, to = "/", className } = props;
        let actualProps = _.omit(props, ["width", "height", "className"]);
        let style = {};
        let Element = typeof to !== "undefined" && to.length > 0 ? Link : "div";

        if (typeof width === "number" || typeof height === "number") {
            if (width > 0) {
                let adjustedHeight = this._aspectRatio(width, "height");
                style = {
                    width,
                    height: adjustedHeight,
                    backgroundSize: `${width}px ${adjustedHeight}px`
                };
            } else {
                let adjustedWidth = this._aspectRatio(height, "width");
                style = {
                    width: adjustedWidth,
                    height,
                    backgroundSize: `${adjustedWidth}px ${height}px`
                };
            }
        }

        let logoClass = "om logo";
        if (typeof className !== "undefined" && className.length > 0)
            logoClass = `${logoClass} ${className}`;

        return <Element to={to} className={logoClass} style={style} {...actualProps} />;
    }
}

Logo.propTypes = {
    style: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number,
    className: PropTypes.string
};

export { Logo };
