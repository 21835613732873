import _ from "lodash";
import React from "react";
import { Route, Redirect } from "react-router-dom";

import routes from "../../routes.json";

class PrivateRoute extends React.Component {
    state = {
        path: "",
        ready: true,
        redirect: false,
        viewable: true
    };

    componentDidMount() {
        let mainPath = "";
        let loginPath = "";
        const { fn, CONST } = window;
        const { auth = false } = this.props;

        _.each(routes, (v, i) => {
            const { to, login, main } = v;

            if (typeof login !== "undefined" && login === true) {
                if (typeof to !== "undefined" && to.length > 0) {
                    loginPath = to;
                    this.setState({ path: to });
                }
            } else if (typeof main !== "undefined" && main === true) {
                if (typeof to !== "undefined" && to.length > 0) mainPath = to;
            }
        });

        // We're currently logging in
        const isLoggingIn = _.get(this.props, "location.pathname") === loginPath;
        if (auth === true || isLoggingIn) {
            if (auth === true && !isLoggingIn) this._checkPermission();

            fn.tokenize("is_loggedin").then(token => {
                let params = { token };

                if (CONST.IS_DEV && typeof process.env.REACT_APP_DEV_KEY !== "undefined") {
                    params = {
                        ...params,
                        dev: CONST.IS_DEV,
                        dev_key: process.env.REACT_APP_DEV_KEY,
                        session_id: localStorage.getItem("session_id")
                    };
                }

                fn.get("is_loggedin", { params })
                    .then(o => {
                        const { data } = o;
                        const { loggedin } = data;
                        const isLoggedIn = loggedin === true;

                        if (!isLoggingIn && !isLoggedIn) {
                            // If the user is not logged in and accessing private stuffs, redirect
                            // them back to the login page.
                            this.setState({ redirect: true });
                        } else if (isLoggingIn && isLoggedIn && mainPath !== "") {
                            // Otherwise, the user is on login page and they're already logged in. So
                            // we're redirecting them to mainPath.
                            this.setState({ redirect: true, path: mainPath });
                        }
                    })
                    .catch(e => false);
            });
        }
    }

    _checkPermission = () => {
        const { fn } = window;
        const { location } = this.props;
        const { pathname } = location;

        fn.tokenize("can_view").then(token => {
            const params = { token, pathname };

            fn.get("/can_view", { params }).then(o => {
                const { data } = o;
                const { viewable } = data;

                this.setState({ viewable });
            });
        });
    };

    render() {
        const { location, permError, loadingComponent } = this.props;
        const { redirect, viewable, ready, path } = this.state;
        const { pathname } = location;

        if (viewable) {
            if (ready === true) {
                const props = _.omit(this.props, ["auth"]);

                if (redirect === true && pathname !== path) {
                    if (typeof path !== "undefined" && path.length > 0) {
                        return <Redirect to={path} />;
                    } else {
                        return <div>Unauthorised.</div>;
                    }
                } else {
                    return <Route {...props} />;
                }
            } else {
                if (typeof loadingComponent !== "undefined") return loadingComponent;
            }
        } else {
            return permError || <Redirect to="/404" />;
        }

        return null;
    }
}

export { PrivateRoute };
