import _ from "lodash";
import React from "react";
import { Grid } from "semantic-ui-react";
import { Card, CardContent, Button, Heading, Markdown } from "..";

class SectionHighlight extends React.Component {
    render() {
        const { settings } = this.props;
        const { highlights } = settings;

        return (
            <section className="aibim highlight">
                <div className="decorative top" />
                <div className="highlight-content">
                    <Grid columns={3} doubling stackable>
                        {_.times(3, i => {
                            const currentHighlight = _.get(highlights, `[${i}]`, {});
                            const { title, links = [], primary_link } = currentHighlight;

                            let points = [];
                            _.each(links, item => {
                                const { text, link } = item;
                                points = [...points, `- [${text}](${link})`];
                            });

                            return (
                                <Grid.Column key={i}>
                                    <Card>
                                        <CardContent>
                                            <Heading text={title} style={{ fontWeight: 700 }} />
                                            <Markdown source={points.join("\n")} />
                                            {primary_link && (
                                                <Button
                                                    to={primary_link}
                                                    size="large"
                                                    icon="far external-link"
                                                    color="blue"
                                                />
                                            )}
                                        </CardContent>
                                    </Card>
                                </Grid.Column>
                            );
                        })}
                    </Grid>
                </div>
                <div className="decorative bottom" />
            </section>
        );
    }
}

export { SectionHighlight };
