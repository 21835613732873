import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Icon = props => {
    let { name, icon } = props;
    let actualProps = _.omit(props, ["name", "icon"]);

    if (typeof name === "undefined" && typeof icon !== "undefined") name = icon;

    if (name.indexOf(" ") !== -1) name = name.split(" ");
    return <FontAwesomeIcon icon={name} {...actualProps} />;
};

Icon.propTypes = {
    name: PropTypes.string
};

export { Icon };
