import _ from "lodash";
import React from "react";
import { Heading, CardContent } from "..";

class SectionMember extends React.Component {
    render() {
        let { members } = this.props;
        const adminURL = window.CONST.API_URL;
        members = _.sortBy(members, (o) => o.name);

        if (_.isArray(members) && members.length > 0) {
            return (
                <section className="aibim member">
                    <CardContent>
                        <Heading
                            as="h4"
                            text="AIBIM Members"
                            subtitle="AIBIM Member Banks and Financial Institutions"
                            className="blue section-header"
                        />
                        <div className="logos">
                            {members.map((item, i) => {
                                const { link, name, logo } = item;
                                const { url: logoURL = "" } = logo;

                                if (link !== undefined && link !== "" && logoURL) {
                                    return (
                                        <a rel="noopener noreferrer" key={i} href={link} target="_blank">
                                            <img key={i} src={`${adminURL}${logoURL}`} alt={name} />
                                        </a>
                                    );
                                } else {
                                    return (
                                        <img key={i} src={require(`../../media/logos/${logo}`)} alt={name} />
                                    );
                                }
                            })}
                        </div>
                    </CardContent>
                </section>
            );
        } else {
            return "";
        }
    }
}

export { SectionMember };
