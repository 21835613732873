import _ from "lodash";
import React from "react";
import GlobalRedux from "./GlobalRedux";

class Global extends React.Component {
    render() {
        const props = _.omit(this.props, ["children"]);
        const { children } = this.props;

        return <GlobalRedux children={children} {...props} />;
    }
}

export { Global };
