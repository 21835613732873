import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Icon } from "..";

class Heading extends React.Component {
    render() {
        let { as = "h1", text, subtitle, icon, iconProps, className } = this.props;
        if (HEADING_ELEMENTS.indexOf(as) < 0) as = "h1";

        let HeadingElement = as;
        let headerClass = "ui header";
        let content = text;
        let props = _.omit(this.props, [
            "as",
            "text",
            "icon",
            "children",
            "iconProps",
            "className"
        ]);

        if (typeof className !== "undefined" && className.length > 0)
            headerClass = `${headerClass} ${className}`;
        if (typeof _.get(iconProps, "className") !== "undefined")
            iconProps.className = `${iconProps.className} icon`;

        if (typeof subtitle !== "undefined") {
            content = (
                <>
                    {content}
                    <div className="sub header">{subtitle}</div>
                </>
            );
        }

        if (typeof icon !== "undefined" && icon.length > 0) {
            if (typeof subtitle !== "undefined") {
                content = <div className="content">{content}</div>;
            }

            return (
                <HeadingElement className={headerClass} {...props}>
                    <div className="icon" {...iconProps}>
                        <Icon name={icon} />
                    </div>
                    {content}
                </HeadingElement>
            );
        } else {
            return (
                <HeadingElement className={headerClass} {...props}>
                    {content}
                </HeadingElement>
            );
        }
    }
}

const HEADING_ELEMENTS = ["h1", "h2", "h3", "h4", "h5", "h6", "div"];

Heading.propTypes = {
    as: PropTypes.oneOf(HEADING_ELEMENTS),
    icon: PropTypes.string,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node)
    ]),
    className: PropTypes.string,
    iconProps: PropTypes.object
};

export { Heading };
