import React from "react";
import PropTypes from "prop-types";
import { Icon, Heading } from ".";

class RelLinks extends React.Component {
    render() {
        const { title, subpages, onChange, currentIndex = 0 } = this.props;

        return (
            <div className="toc">
                <Heading as="h5" text={title || "Table of Contents"} />

                <ul>
                    {subpages.map(({ title }, i) => {
                        const active = i === currentIndex;
                        const onClick = active ? null : onChange.bind(null, i);
                        const className = active ? "active" : "";

                        return (
                            <li key={i} className={className} onClick={onClick}>
                                <div className="icon">
                                    <Icon name="far chevron-right" />
                                </div>
                                <div className="title">{title}</div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}

RelLinks.propTypes = {
    links: PropTypes.array,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    markdown: PropTypes.bool,
};

export { RelLinks };
