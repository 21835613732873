import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Icon, Link } from "../";

class PageTab extends React.Component {
    constructor(props) {
        super(props);
        this._onClick = this._onClick.bind(this);
    }

    _onClick = (e, index) => {
        const { onClick, currentIndex } = this.props;

        if (currentIndex === index) return false;
        if (typeof onClick === "function") onClick(e, index);
    };

    render() {
        let { tabs, base, currentIndex = 0 } = this.props;

        return (
            <div className="om tabs page">
                {tabs.map((item, index) => {
                    let { to, id, icon, label } = item;
                    let props = _.omit(item, ["to", "icon", "label"]);
                    let onClick = this._onClick.bind(this, index);
                    let itemClass = "item";

                    if (currentIndex === index) itemClass = `active ${itemClass}`;
                    if (typeof base === "string" && base.length > 0) {
                        to = typeof id !== "undefined" ? `/${base}/${id}` : "";
                    }

                    if (typeof to === "string" && to.length > 0) {
                        return (
                            <Link key={index} to={to} onClick={onClick} {...props}>
                                <div key={index} className={itemClass} {...props}>
                                    <Icon name={icon} />
                                    <span className="label">{label}</span>
                                </div>
                            </Link>
                        );
                    } else {
                        return (
                            <div key={index} onClick={onClick} className={itemClass} {...props}>
                                <Icon name={icon} />
                                <span className="label">{label}</span>
                            </div>
                        );
                    }
                })}
            </div>
        );
    }
}

PageTab.propTypes = {
    tabs: PropTypes.array.isRequired,
    base: PropTypes.string,
    currentIndex: PropTypes.number
};

export { PageTab };
