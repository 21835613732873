import _ from "lodash";
import qs from "qs";
import api from "../api";
import CONST from "../includes/Constants";
import dateFormat from "dateformat";

export default {
    searchArray: function (object, keyword, lookup) {
        let results = [];
        let inArray = (needle, haystack) => {
            let length = haystack.length;
            for (let i = 0; i < length; i++) {
                if (haystack[i] === needle) return true;
            }

            return false;
        };

        let trimString = (s) => {
            let l = 0,
                r = s.length - 1;
            while (l < s.length && s[l] === " ") l++;
            while (r > l && s[r] === " ") r -= 1;
            return s.substring(l, r + 1);
        };

        let compareObjects = (o1, o2) => {
            let k = "";
            for (k in o1) if (o1[k] !== o2[k]) return false;
            for (k in o2) if (o1[k] !== o2[k]) return false;
            return true;
        };

        let itemExists = (haystack, needle) => {
            for (let i = 0; i < haystack.length; i++) if (compareObjects(haystack[i], needle)) return true;
            return false;
        };

        // Do the search
        keyword = trimString(keyword);
        for (let i = 0; i < object.length; i++) {
            for (let key in object[i]) {
                let value = object[i][key];
                if (
                    typeof value !== "string" ||
                    (typeof lookup !== "undefined" && lookup > 0 && !inArray(key, lookup))
                )
                    continue;

                let has_position = value.indexOf(keyword) !== -1;
                let split_needle = keyword.split(" ");
                let split_truth = {};

                // Fills everything in the split_truth as false by default
                for (let v in split_needle) split_truth[split_needle[v]] = false;

                for (let k in split_needle) {
                    let each_keyword = split_needle[k].toLowerCase();
                    if (value.toLowerCase().indexOf(each_keyword) !== -1) split_truth[split_needle[k]] = true;
                }

                has_position = has_position || !inArray(false, Object.values(split_truth));
                if (has_position && !itemExists(results, object[i])) results.push(object[i]);
            }
        }

        return results;
    },

    date: (date, format = "dddd, mmmm dS, yyyy") => {
        return dateFormat(new Date(date), format);
    },

    hideProgressBar: (props) => {
        if (typeof props.site === "undefined") return false;

        if (props.site.progressBarStatus === true) props.setProgressBar(false);

        return true;
    },

    renderIf(condition, content, fallback = null) {
        return condition ? content : fallback;
    },

    renderif(condition, content, fallback = null) {
        return condition ? content : fallback;
    },

    getDayName(d) {
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        return days[d];
    },

    getMonthName(m) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        return months[m];
    },

    getMonthFullName(m) {
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        return months[m];
    },

    numberFormat(x, sep = ",") {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sep);
    },

    nf(x, sep = ",") {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sep);
    },

    avatar(name = "default", style = "male") {
        let styles = ["male", "female", "identicon", "bottts", "avataaars", "jdenticon", "gridy"];
        if (styles.indexOf(style) < 0) style = "bottts";
        return `https://avatars.dicebear.com/v2/${style}/${name}.svg`;
    },

    truncate(string, length = 30, dots = true) {
        if (typeof length !== "number") length = 30;
        if (typeof string !== "string") return string;
        if (string.length < 1 || string.length < length) return string;

        return string.substring(0, length) + (dots ? " …" : "");
    },

    /**
     * Hide alert.
     *
     * @since   0.1
     */
    hideAlert(props, id) {
        let popups = _.get(props, "site.alert");

        if (_.isArray(popups) && popups.length > 0) {
            const index = _.findIndex(popups, (x) => x.id === id);

            if (index > -1) {
                popups[index] = { ...popups[index], show: false };
                if (typeof props.pruneAlert === "function") {
                    props.hideAlert(popups);
                }
            }
        }
    },

    /**
     * Get the HTML meta tags.
     *
     * @since   0.1
     */
    getMeta() {
        let meta = [
            {
                name: "apple-mobile-web-app-capable",
                content: "yes",
            },
            {
                name: "apple-mobile-web-app-status-bar-style",
                content: "white",
            },
        ];

        // Generate meta data
        const sizes = [
            {
                rel: "apple-touch-icon-precomposed",
                sizes: [57, 60, 72, 76, 120, 114, 144, 152],
                filename: "apple-touch-icon-",
                filetype: "png",
            },
            {
                rel: "icon",
                type: "image/png",
                sizes: [16, 32, 96, 128, 196],
                filename: "favicon-",
                filetype: "png",
            },
        ];

        sizes.map((item, i) => {
            const { rel, type, filename, filetype } = item;
            const iconSizes = item.sizes;

            return iconSizes.map((size) => {
                const times = `${size}x${size}`;
                return (meta = [
                    ...meta,
                    {
                        rel,
                        type,
                        href: `/favicon/${filename}${times}.${filetype}`,
                        sizes: times,
                        metaType: "link",
                    },
                ]);
            });
        });

        return meta;
    },

    /**
     * Get token string based on name.
     *
     * @param   string  name    The endpoint name.
     */
    async tokenize(name) {
        return await api
            .get("/tokenize", { params: { name } })
            .then((o) => {
                const { data } = o;
                const { token } = data;

                return token;
            })
            .catch((e) => false);
    },

    /**
     * Axios post with predefined options.
     *
     * @since   0.1
     */
    async post(endpoint, data, options = {}) {
        let config = CONST.POST_CONFIG;
        if (typeof options === "object" && Object.keys(options).length > 0)
            config = { ...config, ...options };

        return await api.post(endpoint, qs.stringify(data), config);
    },

    /**
     * Axios get request.
     *
     * @param   string  endpoint    The API endpoint.
     * @param   object  options     The request option.
     * @since   0.1
     */
    async get(endpoint, options = {}) {
        return await api.get(endpoint, options);
    },

    /**
     * Send a new alert based on response data.
     *
     * @since   0.1
     */
    sendAlert(props, data) {
        const { message, color } = data;

        if (typeof message !== "undefined" && message.length > 0) props.newAlert(message, color || "blue");

        return true;
    },

    ////////////////////////////////////////////////////////
    //  Anything below here are custom functions created  //
    //              for specific project.                 //
    ////////////////////////////////////////////////////////
    segmentSkeleton() {
        return [
            {
                id: null,
                name: "",
                answers: [""],
                sections: [
                    {
                        id: "",
                        name: "",
                        questions: [
                            {
                                id: null,
                                featured: false,
                                question: "",
                            },
                        ],
                    },
                ],
            },
        ];
    },

    /**
     * Generate unique ID. This should not be used for data.
     *
     * @return  string
     * @since   0.1
     */
    unique() {
        return Math.random().toString(36).substr(2, 12);
    },
};
