import React from "react";
import PropTypes from "prop-types";
import { Button, ReactHelmet } from "..";

class ErrorPage extends React.Component {
    componentDidMount() {
        const { fn } = window;
        const { props } = this.props;

        if (typeof props === "object" && Object.keys(props).length > 0) {
            fn.hideProgressBar(props);
        }
    }

    render() {
        const { CONST } = window;
        const { heading, subtitle, buttonProps, useHelmet = false } = this.props;

        return (
            <>
                {useHelmet === true && <ReactHelmet title={`${heading} – ${CONST.SITENAME}`} />}
                <div className="error container">
                    <div className="overlay">
                        <div className="content">
                            <h1 className="ui title header">
                                {heading}
                                {subtitle && <div className="sub header">{subtitle}</div>}
                            </h1>

                            {buttonProps && (
                                <>
                                    <br className="ui horizontal divider" />
                                    <Button color="white" circular {...buttonProps} />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

ErrorPage.propTypes = {
    heading: PropTypes.string,
    subtitle: PropTypes.string,
    useHelmet: PropTypes.bool,
    buttonProps: PropTypes.object
};

export { ErrorPage };
