import _ from 'lodash';
import React from 'react'
import PropTypes from 'prop-types'

class Tooltip extends React.Component {
    render() {
        const { text, children, bubbleStyle = {} } = this.props;
        const props = _.omit(this.props, ['text', 'children', 'bubbleStyle']);

        return (
            <span className="tooltip anchor" {...props}>
                {children}
                <span className="text" style={bubbleStyle}>
                    {text}
                </span>
            </span>
        );
    }
}

Tooltip.propTypes = {
    text: PropTypes.string,
    bubbleStyle: PropTypes.object
}

export { Tooltip }