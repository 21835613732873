import _ from "lodash";
import React from "react";
import LinkRedux from "./LinkRedux";
import { withRouter } from "react-router-dom";

class LinkComponent extends React.Component {
    render() {
        const rest = _.omit(this.props, ["children"]);
        const { children } = this.props;

        return <LinkRedux {...rest}>{children}</LinkRedux>;
    }
}

const Link = withRouter(props => <LinkComponent {...props} />);

export { Link };
