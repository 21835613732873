import _ from "lodash";
import * as actions from "../actions";
import React from "react";
import { connect } from "react-redux";
import { Grid, Form, Loader } from "semantic-ui-react";
import {
    Link,
    Field,
    Global,
    Header,
    Heading,
    Content,
    CardContent,
    SectionFooter,
    Message
} from "../components";

class Search extends React.Component {
    state = {
        token: "",
        query: "",
        results: [],
        loading: true
    };

    componentDidMount() {
        const { fn } = window;
        const query = _.get(this.props, "match.params.query", "");

        this.setState({ query });
        fn.tokenize("search").then(token => {
            this.setState({ token });
            this._search(query, token);
        });
    }

    _search = (query, token) => {
        this.setState({ loading: true });

        window.fn.get("/search", { params: { token, query } }).then(o => {
            const { data } = o;
            const results = _.isArray(data) && data.length > 0 ? data : [];

            this.setState({ results, loading: false });
            this.props.history.push("/search/" + query);
        });
    };

    _onChange = (e, input) => {
        const { value } = input || e.target;

        if (typeof value !== "undefined") this.setState({ query: value });
    };

    _onSearch = (e, input) => {
        if (e.key.toLowerCase() === "enter") {
            const { token } = this.state;
            const { value = "" } = input || e.target;

            this._search(value.replace(/ /g, "+"), token);
        }
    };

    render() {
        const width = 10;
        const query = _.get(this.props, "match.params.query", "").replace(/\+/g, " ");
        const { settings = {} } = this.props.data;
        const { footnote = {} } = settings;
        const headingTitle =
            query && query.length > 0 ? `Search results for "${query}"` : "Search results";

        return (
            <Global title="Search">
                <Header title="Search AIBIM" />

                <Content footerLeft={footnote.left} footerRight={footnote.right}>
                    <CardContent>
                        <Grid doubling stackable>
                            <Grid.Column width={width}>
                                {query && (
                                    <Heading
                                        as="h4"
                                        icon="far search"
                                        text={headingTitle}
                                        style={{ marginTop: 5 }}
                                    />
                                )}
                                <Search.Content state={this.state} query={query} />
                            </Grid.Column>

                            <Grid.Column width={16 - width}>
                                <Form onKeyPress={this._onSearch}>
                                    <Field
                                        fluid
                                        size="large"
                                        type="text"
                                        icon="search"
                                        value={_.get(this.state, "query", "")}
                                        onChange={this._onChange}
                                        placeholder="Type to search"
                                    />
                                    <small className="light text">
                                        Type and hit 'Enter' to search.
                                    </small>
                                </Form>
                            </Grid.Column>
                        </Grid>
                    </CardContent>

                    <SectionFooter settings={settings} />
                </Content>
            </Global>
        );
    }

    static Content(props) {
        const { state, query } = props;
        const { results = [], loading = true } = state;

        if (!loading) {
            if (_.isArray(results) && results.length > 0) {
                return (
                    <div className="search container">
                        {results.map((result, index) => {
                            const { url, title, slug, type, excerpt } = result;
                            const anchor = type === "post" ? `/news/${slug}` : `/${slug}`;

                            return (
                                <div className="item" key={index}>
                                    <Link to={anchor}>
                                        <Heading as="h5" text={title} subtitle={url} />
                                    </Link>
                                    <p>{excerpt || "No description available."}</p>
                                </div>
                            );
                        })}
                    </div>
                );
            } else {
                if (query !== "") {
                    return (
                        <Heading
                            as="h5"
                            text="Sorry, content not found."
                            subtitle="Looks like the thing you're looking for is not found. Don't worry, you head back to our homepage or try another search."
                        />
                    );
                } else {
                    return (
                        <Message
                            icon="far search"
                            text="Please type keywords in the search box to start searching."
                            title="Type something to search"
                        />
                    );
                }
            }
        } else {
            return <Loader active inline="centered" />;
        }
    }
}

const mapStateToProps = state => state;
export default connect(mapStateToProps, actions)(Search);
