import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Grid, Divider } from "semantic-ui-react";
import { Icon, Link, Heading, EmptyState } from "..";

class Albums extends React.Component {
    render() {
        let { slug, list = [], folder = "photos", albums = false } = this.props;
        const columns = albums ? 3 : 4;
        const className = albums ? "om albums gallery" : "om gallery";
        const index = _.findIndex(list, (o) => o.slug === slug);

        if (slug !== "" && albums === false) list = _.get(list, `[${index}].items`, []);
        if (_.isArray(list) && list.length > 0) {
            return (
                <div className={className}>
                    <Albums.Content
                        list={list}
                        folder={folder}
                        albums={albums}
                        columns={columns}
                        className={className}
                    />
                </div>
            );
        } else {
            return <Albums.Empty />;
        }
    }

    static Content(props) {
        const { list, albums = false, folder = "photos", columns, className } = props;

        if (albums === true) {
            return (
                <div className={className}>
                    <Grid columns={columns} doubling stackable stretched>
                        {list.map((item, index) => (
                            <Grid.Column key={index}>
                                <Albums.Item item={item} albums={albums} folder={folder} />
                            </Grid.Column>
                        ))}
                    </Grid>
                </div>
            );
        } else {
            let images = [];
            let videos = [];
            _.each(list, (item) => {
                const { type = "image" } = item;

                switch (type) {
                    default:
                    case "image":
                        images = [...images, item];
                        break;

                    case "video":
                    case "youtube":
                        videos = [...videos, item];
                        break;
                }
            });

            if (images.length > 0 || videos.length > 0) {
                return (
                    <div className={className}>
                        {images.length > 0 && (
                            <React.Fragment>
                                <Heading as="h5" text="Photos" icon="far camera" />
                                <Grid columns={columns} doubling stackable stretched>
                                    {images.map((item, index) => (
                                        <Grid.Column key={index}>
                                            <Albums.Item item={item} albums={albums} folder={folder} />
                                        </Grid.Column>
                                    ))}
                                </Grid>
                                <Divider hidden />
                            </React.Fragment>
                        )}

                        {videos.length > 0 && (
                            <React.Fragment>
                                <Heading as="h5" text="Videos" icon="far film-alt" />
                                <Grid columns={columns} doubling stackable>
                                    {videos.map((item, index) => (
                                        <Grid.Column key={index}>
                                            <Albums.Item item={item} albums={albums} folder={folder} />
                                        </Grid.Column>
                                    ))}
                                </Grid>
                            </React.Fragment>
                        )}
                    </div>
                );
            } else {
                return <Albums.Empty />;
            }
        }
    }

    static Item(props) {
        const { fn } = window;
        const { item, albums = false, folder = "photos" } = props;
        const { title, name, date, created_at, slug } = item;
        const link = `/${folder}/${slug}`;

        if (albums === true) {
            return (
                <div className="item">
                    <div className="boxes">
                        <div className="box"></div>
                        <div className="box"></div>
                        <Link to={link}>
                            <div className="box">
                                <Albums.Media item={item} albums noHyperlink />
                            </div>
                        </Link>
                    </div>

                    <div className="title">
                        <Heading
                            as="h6"
                            text={name || "Untitled Album"}
                            subtitle={date ? fn.date(date || created_at) : ""}
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <div className="item">
                    <div className="box">
                        <Albums.Media item={item} />
                    </div>

                    {title && title.length > 0 && (
                        <div className="title" style={{ paddingTop: 10 }}>
                            <Heading as="h6" text={title} />
                        </div>
                    )}
                </div>
            );
        }
    }

    static Media(props) {
        let image = "";
        let { item, albums, noHyperlink = false } = props;
        let { url, name, type = "image" } = item;
        let thumbnail = url;
        const adminURL = window.CONST.API_URL;

        switch (type) {
            default:
            case "image":
                url = `${adminURL}/${_.trimStart(url, "/")}`;
                if (albums === true) {
                    // Select the first image as album cover
                    const { items = [] } = item;
                    thumbnail = _.get(_.first(items), "formats.small.url", "");
                } else {
                    thumbnail = _.get(item, "formats.small.url", url);
                }

                image = <img src={`${adminURL}/${_.trimStart(thumbnail, "/")}`} alt={name} />;
                break;

            case "youtube":
                if (albums === true) {
                    const { items = [] } = item;
                    url = _.get(_.first(items), "url");
                }

                const urlSplit = url.split("/");
                const videoId = urlSplit[urlSplit.length - 1];
                thumbnail = `http://img.youtube.com/vi/${videoId}/0.jpg`;

                image = (
                    <>
                        <img src={thumbnail} alt={url} />
                        <Icon name="fas play" className="icon" />
                    </>
                );
                break;
        }

        if (noHyperlink) return image;
        return (
            <a href={url} target="_blank" rel="noopener noreferrer">
                {image}
            </a>
        );
    }

    static Empty() {
        return (
            <EmptyState
                title="Gallery is unavailable"
                artName="undraw_image_focus_wm20.svg"
                subtitle="There's currently no item to show in the gallery"
            />
        );
    }
}

Albums.propTypes = {
    slug: PropTypes.string,
    list: PropTypes.array,
    albums: PropTypes.bool,
};

export { Albums };
