import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

class Shimmer extends React.Component {
    render() {
        const {
            radius = false,
            height,
            width,
            style,
            shimmers,
            borderRadius,
            marginBottom = 20,
            containerStyle,
            shimmerColor,
            backgroundColor
        } = this.props;

        const defaultWidth = "30%";
        const defaultHeight = 15;

        let defaultStyle = {
            width: width || defaultWidth,
            height: height || defaultHeight,
            display: "block",
            marginBottom: marginBottom,
            borderRadius: borderRadius || (radius ? height / 2 : 0),
            ...style
        };

        if (typeof shimmerColor !== "undefined" || typeof backgroundColor !== "undefined") {
            let shimmerBg = typeof shimmerColor !== "undefined" ? shimmerColor : "#edeef1";
            let background = typeof backgroundColor !== "undefined" ? backgroundColor : "#f6f7f8";

            defaultStyle = {
                ...defaultStyle,
                background: background,
                backgroundImage: `linear-gradient(
                    to right,
                    ${background} 0%,
                    ${shimmerBg} 20%,
                    ${background} 40%,
                    ${background} 100%
                )`
            };
        }

        const contStyle = {
            display: "block",
            ...containerStyle
        };

        if (shimmers && shimmers.length > 0) {
            return (
                <div style={contStyle}>
                    {shimmers.map((e, k) => {
                        let style = _.omit(e, ["props"]);
                        style = { ...defaultStyle, ...style };

                        let { width, height, marginBottom, borderRadius } = style;

                        const { props } = e;
                        const hasWidth = typeof width !== "undefined";
                        const hasHeight = typeof height !== "undefined";
                        const hasMargin = typeof marginBottom !== "undefined";

                        // Check for height
                        if (!hasHeight) {
                            height = defaultHeight;
                            style = { ...style, height };
                        }

                        if (!hasWidth) {
                            width = defaultWidth;
                            style = { ...style, width };
                        }

                        if (radius && typeof borderRadius !== "undefined") {
                            borderRadius = height / 2;
                            style = { ...style, borderRadius };
                        }

                        if (hasMargin && k === shimmers.length - 1) {
                            marginBottom = 0;
                            style = { ...style, marginBottom };
                        }

                        return <div key={k} style={style} className="om shimmer" {...props} />;
                    })}
                </div>
            );
        } else {
            return (
                <div style={contStyle}>
                    <div style={defaultStyle} className="om shimmer" />
                </div>
            );
        }
    }
}

Shimmer.propTypes = {
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    radius: PropTypes.bool,
    shimmers: PropTypes.array,
    marginBottom: PropTypes.number,
    borderRadius: PropTypes.number,
    containerStyle: PropTypes.object
};

export { Shimmer };
