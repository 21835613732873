import _ from "lodash";
import * as actions from "../actions";
import React from "react";
import parse from "url-parse";
import { connect } from "react-redux";
import { Grid, Loader } from "semantic-ui-react";
import { RelLinks, Global, Header, Content, Markdown, CardContent, SectionFooter } from "../components";

class Subpage extends React.Component {
    state = {
        init: false,
        data: {},
        loading: true,
        viewable: true,
        currentSlug: "",
        currentSubpageIndex: 0,
    };

    componentDidUpdate() {
        const path = _.get(this.props, "match.path", "");
        const params = _.get(this.props, "match.params", {});
        const { loading, currentSlug } = this.state;
        const { slug, page } = params;
        const { fn } = window;

        const isNews = path === "/news/:slug";
        const id = isNews ? slug : page;
        const endpoint = isNews ? "posts" : "pages";

        if (id !== currentSlug) {
            if (loading === false) this.setState({ loading: true });

            fn.get(`/${endpoint}`, { params: { slug: id } }).then((o) => {
                const { data } = o;
                const { title = "" } = data[0] || {};
                const emptyState = {
                    viewable: false,
                    data: {
                        title: "404 Not Found",
                        content:
                            "#### Content not found.\nThe content that you are looking for cannot be found on our website.",
                    },
                };

                const state = title.length > 0 ? { data: data[0] } : emptyState;
                this.setState({ ...state, loading: false, currentSlug: id });
            });
        }
    }

    _imageRender = (props) => {
        const actualProps = _.omit(props, ["src", "alt"]);

        const { CONST } = window;
        const { API_URL, IS_DEV } = CONST;
        const { src, alt } = props;

        const parseUrl = parse(API_URL, true);
        const { protocol, host } = parseUrl;
        const baseUrl = IS_DEV ? `${protocol}//${host}` : "";
        const imgSource = `${baseUrl}/${_.trimStart(src, "/")}`;

        return <img src={imgSource} alt={alt} {...actualProps} />;
    };

    _onChangeSubpage = (currentSubpageIndex) => {
        this.setState({ currentSubpageIndex });
    };

    render() {
        const { data } = this.state;
        const { settings = {} } = this.props.data;
        const { footnote = {} } = settings;
        const { title, subtitle = "", parentTitle = "" } = data;

        return (
            <Global title={title}>
                <Header title={parentTitle || title} subtitle={subtitle} />

                <Content footerLeft={footnote.left} footerRight={footnote.right}>
                    <CardContent>
                        <Subpage.Content
                            data={data}
                            state={this.state}
                            imageRender={this._imageRender}
                            onChangeSubpage={this._onChangeSubpage}
                        />
                    </CardContent>

                    <SectionFooter settings={settings} />
                </Content>
            </Global>
        );
    }

    static Content(props) {
        const { data, state, imageRender, onChangeSubpage } = props;
        const { subpages, subpage_title, title, content } = data;
        const { loading, currentSubpageIndex } = state;

        if (loading === false) {
            if (_.isArray(subpages) && subpages.length > 0) {
                return (
                    <Grid stackable>
                        <Grid.Column width={5}>
                            <RelLinks
                                title={subpage_title || title}
                                subpages={subpages}
                                onChange={onChangeSubpage}
                                currentIndex={currentSubpageIndex}
                            />
                        </Grid.Column>

                        <Grid.Column width={11}>
                            <Markdown
                                source={_.get(subpages, `[${currentSubpageIndex}].content`, content)}
                                escapeHtml={false}
                                renderers={{ image: imageRender }}
                            />
                        </Grid.Column>
                    </Grid>
                );
            } else {
                return <Markdown source={content} escapeHtml={false} renderers={{ image: imageRender }} />;
            }
        } else {
            return <Loader active inline="centered" />;
        }
    }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps, actions)(Subpage);
