import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Buttons, Heading } from "..";

class EmptyState extends React.Component {
    render() {
        const { as = "h4", title, style, buttons, artName, width, src } = this.props;
        const props = _.omit(this.props, ["as", "title", "buttons", "art", "style", "artName"]);
        const useArtName = typeof artName !== "undefined" && artName.length > 0;
        const illustration = useArtName ? require(`../../media/arts/${artName}`) : src;

        let artStyle = {};
        if (typeof width === "number" || typeof width === "string") {
            artStyle = { width };
        }

        return (
            <div className="om empty" style={style}>
                <div>
                    {illustration && (
                        <img src={illustration} style={artStyle} alt="content-empty" />
                    )}
                    <Heading as={as} text={title} {...props} />
                    {buttons && <Buttons buttons={buttons} pull="center" />}
                </div>
            </div>
        );
    }
}

EmptyState.propTypes = {
    as: PropTypes.string,
    src: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    artName: PropTypes.string,
    buttons: PropTypes.arrayOf(PropTypes.object)
};

export { EmptyState };
