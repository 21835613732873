import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { PieChart, Pie, Legend, Tooltip, Cell, ResponsiveContainer } from "recharts";

class ChartPie extends React.Component {
    render() {
        const {
            pies,
            // width = 400,
            height = 400,
            legend = true,
            tooltip = true,
            legendProps,
            tooltipProps
        } = this.props;
        const actualProps = _.omit(this.props, [
            "pies",
            // "width",
            // "height",
            "legend",
            "tooltip",
            "legendProps",
            "tooltipProps"
        ]);

        // Blend default props with the customs
        const _legendProps = {
            verticalAlign: "bottom",
            ...legendProps
        };
        const _tooltipProps = {
            cursor: false,
            contentStyle: {
                color: "#485e72",
                padding: 10,
                background: "#fff",
                lineHeight: 1,
                borderWidth: 0,
                borderRadius: 5
            },
            ...tooltipProps
        };

        return (
            <div style={{ width: "100%", height }}>
                <ResponsiveContainer>
                    <PieChart {...actualProps}>
                        {legend === true && <Legend {..._legendProps} />}
                        {tooltip === true && <Tooltip {..._tooltipProps} />}

                        {typeof pies !== "undefined" &&
                            pies.map((v, i) => {
                                const { colors, data } = v;
                                return (
                                    <Pie key={i} {...v}>
                                        {typeof colors !== "undefined" &&
                                            colors.length > 0 &&
                                            data.map((e, k) => (
                                                <Cell key={k} fill={colors[k % colors.length]} />
                                            ))}
                                    </Pie>
                                );
                            })}
                    </PieChart>
                </ResponsiveContainer>
            </div>
        );
    }
}

ChartPie.propTypes = {
    data: PropTypes.array,
    pies: PropTypes.array,
    height: PropTypes.number,
    legend: PropTypes.bool,
    tooltip: PropTypes.bool,
    legendProps: PropTypes.object,
    tooltipProps: PropTypes.object
};

export { ChartPie };
