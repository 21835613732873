import _ from "lodash";
import React from "react";
import { Grid } from "semantic-ui-react";
import { Button, Heading, MiniNews, CardContent } from "..";

class SectionPrimary extends React.Component {
    state = {
        news: [],
        newsLoading: true,
    };

    componentDidMount() {
        window.fn.get("/posts?_sort=date:DESC").then((o) => {
            this.setState({
                news: _.isArray(o.data) && o.data.length > 0 ? o.data : [],
                newsLoading: false,
            });
        });
    }

    render() {
        const { news, newsLoading } = this.state;
        const link = _.get(this.props.settings, "home_video", "https://www.youtube.com/embed/d2UuDCgHxMg");

        return (
            <CardContent>
                <Grid className="equal height" stackable>
                    <Grid.Column width={6}>
                        <Heading
                            as="h4"
                            text="Latest News"
                            subtitle="Latest news around the Islamic Finance."
                            className="blue"
                        />
                        <MiniNews
                            news={news}
                            loading={newsLoading}
                            style={{ minHeight: 300, maxHeight: 350 }}
                        />
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <div className="aibim video">
                            <iframe
                                src={link}
                                title="video"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope"
                                className="youtube"
                                frameBorder="0"
                                allowFullScreen
                            />
                        </div>

                        <Button
                            to="/videos"
                            icon="far arrow-right"
                            text="Watch more videos"
                            color="blue"
                            transparent
                        />
                    </Grid.Column>
                </Grid>
            </CardContent>
        );
    }
}

export { SectionPrimary };
