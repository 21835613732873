import _ from "lodash";
import parse from "url-parse";
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../actions";

class LinkRedux extends React.Component {
    constructor(props) {
        super(props);
        this._onClick = this._onClick.bind(this);
    }

    _onClick = () => {
        const { onClick } = this.props;

        this.props.setProgressBar(true);
        if (typeof onClick === "function") onClick();
    };

    render() {
        const rest = _.pick(this.props, ["to"]);
        const { to, children, target = "_blank" } = this.props;

        const localURL = parse(window.location.href, true);
        const parsedURL = parse(to, true);

        let { extensions = ["pdf", "png", "jpg", "jpeg"] } = this.props;
        const ext = to !== null && to !== undefined ? to.split(".").pop() : "";
        const hasExtension = extensions.indexOf(ext.toLowerCase()) > -1;

        const hrefHostname = _.get(parsedURL, "hostname");
        const localHostname = _.get(localURL, "hostname");
        const isLocal = hrefHostname === localHostname;

        if (isLocal && !hasExtension) {
            return (
                <Link {...rest} onClick={this._onClick}>
                    {children}
                </Link>
            );
        } else {
            let actualProps = _.pick(this.props, ["style", "title", "className"]);
            if (target === "_blank") {
                actualProps = {
                    ...actualProps,
                    rel: "noopener noreferrer",
                    target: "_blank",
                };
            }

            return (
                <a href={to} {...actualProps}>
                    {children}
                </a>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        ui: state,
    };
};

export default connect(mapStateToProps, actions)(LinkRedux);
