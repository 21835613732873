import _ from "lodash";
import React from "react";
import { Logo, Icon, Field, Tooltip } from ".";

class TopBar extends React.Component {
    state = {
        date: "",
        time: "",
        showSearch: false,
    };

    componentDidMount() {
        const months = [
            "January",
            "February",
            "March",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        setInterval(() => {
            const date = new Date();
            const day = date.getDate();
            const year = date.getFullYear();
            const month = _.get(months, `[${date.getMonth() - 1}]`, "January");

            let meridiem = "AM";
            let hours = date.getHours();
            let minutes = date.getMinutes();

            // Post-meridiem
            if (hours > 12 || (hours >= 12 && minutes > 0)) {
                hours = Math.abs(12 - hours);
                meridiem = "PM";
            }

            if (hours < 10) hours = `0${hours}`;
            if (minutes < 10) minutes = `0${minutes}`;

            this.setState({
                date: `${day} ${month} ${year}`,
                time: `${hours}:${minutes} ${meridiem}`,
            });
        }, 1000);
    }

    _toggleSearch = () => {
        const { showSearch } = this.state;
        this.setState({ showSearch: !showSearch });
    };

    _toggleMenu = () => {
        const { toggleMenu } = this.props;
        if (typeof toggleMenu === "function") toggleMenu();
    };

    _onSearch = (e, input) => {
        if (e.key.toLowerCase() === "enter") {
            let { value } = input || e.target;

            if (typeof value !== "undefined" && value !== "") {
                value = value.replace(/ /g, "+");
                window.location = `/search/${value}`;
            }
        }
    };

    render() {
        const { fn } = window;
        const { date, time, showSearch } = this.state;
        const { fx = "hamburger--spin", social = [], menuOpen } = this.props;
        const hamburgerOpenClass = menuOpen === true ? " is-active" : "";

        return (
            <div className="aibim topbar">
                <div className="left">
                    <Logo to="/" />
                    <div className="tagline">
                        Dynamic, Visible,
                        <br />
                        Responsive & Effective
                    </div>
                </div>
                <div className="right">
                    <div>
                        <ul className="social">
                            {_.isArray(social) &&
                                social.length > 0 &&
                                social.map((props, key) => {
                                    let rest = _.omit(props, ["to", "icon", "feature", "label"]);
                                    let { to, icon, feature, label } = props;

                                    // add color to the social media icons
                                    rest.className = icon;

                                    const bubbleStyle = { marginTop: -1, left: -14 };
                                    const SocialIcon = () => (
                                        <a rel="noopener noreferrer" href={to} target="_blank" {...rest}>
                                            <Icon name={`fab ${icon}`} />
                                        </a>
                                    );

                                    if (feature === "search") {
                                        rest = {
                                            ...rest,
                                            onClick: this._toggleSearch,
                                        };
                                    }

                                    return (
                                        <li key={key}>
                                            {fn.renderif(
                                                label,
                                                <Tooltip text={label} bubbleStyle={bubbleStyle}>
                                                    <SocialIcon />
                                                </Tooltip>,
                                                <SocialIcon />
                                            )}
                                        </li>
                                    );
                                })}
                        </ul>

                        <div className={showSearch ? "search container shown" : "search container"}>
                            <div>
                                <div className="close" onClick={this._toggleSearch}>
                                    <Icon name="far times" />
                                </div>
                                <span className="date">
                                    <label htmlFor="search_input">
                                        {date} &middot; {time}
                                    </label>
                                </span>

                                <Logo width={120} className="animated fadeInDown" />
                                <Field
                                    id="search_input"
                                    type="text"
                                    icon="search"
                                    onKeyPress={this._onSearch}
                                    placeholder="Search.."
                                />
                            </div>
                        </div>

                        <div className="hamburgers" onClick={this._toggleMenu}>
                            <div className={`hamburger ${fx}${hamburgerOpenClass}`}>
                                <div className="hamburger-box">
                                    <div className="hamburger-inner" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br className="clearfix" />
            </div>
        );
    }
}

export { TopBar };
