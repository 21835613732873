import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

class Scrollable extends React.Component {
    render() {
        let { children, maxHeight = 250, hint = false, scrollbar = false, style } = this.props;
        let hintClass = hint === true ? "with-gradient" : "";
        let scrollbarClass = scrollbar === false ? "hidden-scrollbar" : "";
        let actualProps = _.omit(this.props, [
            "children",
            "maxHeight",
            "hint",
            "scrollbar",
            "style"
        ]);

        let scrollbarStyle = { maxHeight };
        if (typeof style !== "undefined") scrollbarStyle = { ...scrollbarStyle, ...style };

        return (
            <div
                className={`scrollable ${scrollbarClass} ${hintClass}`}
                style={scrollbarStyle}
                {...actualProps}
            >
                {children}
            </div>
        );
    }
}

Scrollable.propTypes = {
    hint: PropTypes.bool,
    style: PropTypes.object,
    children: PropTypes.object,
    maxHeight: PropTypes.number,
    scrollbar: PropTypes.bool
};

export { Scrollable };
