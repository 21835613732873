import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Button } from "../";

class Buttons extends React.Component {
    render() {
        let { buttons, pull = "left", darken = false } = this.props;
        let rest = _.omit(this.props, ["buttons", "pull"]);
        if (typeof buttons === "undefined" || buttons.length < 1) return "";

        const pulls = {
            left: "left",
            right: "right",
            center: "center"
        };

        let containerClass = "";
        if (typeof darken !== "undefined" && darken === true) containerClass = " darken";

        return (
            <div className={`${pulls[pull]}${containerClass} button container`} {...rest}>
                {buttons.map((props, k) => (
                    <Button key={k} {...props} />
                ))}
            </div>
        );
    }
}

Buttons.propTypes = {
    pull: PropTypes.string,
    darken: PropTypes.bool,
    buttons: PropTypes.array
};

export { Buttons };
